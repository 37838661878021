import { useContext } from 'react'
import { Route } from 'react-router-dom'

import { Header, Loading } from '../components/coreUI'
import { LoadingContext } from '../features/hooks/useLoadingContext'
import { ThemeContext } from '../features/hooks/useThemeContext'
import { company_details } from '../utils/constants'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NormalRoute = ({ component: Component, ...data }) => {

  const { isLoading } = useContext(LoadingContext)
  const { theme } = useContext(ThemeContext)

  const activePath = (data.location.pathname.split('/')[1].replace('-',' ')) || ''

  const scrollToTop = () =>  {
    const c = document.documentElement.scrollTop || document.body.scrollTop
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop)
      window.scrollTo(0, c - c / 10)
    }
  }

  return (
    <Route
      {...data}
      render={props => {
        console.log({ props })
        scrollToTop()
        return (
          <div className='nehWrapper'>{
            isLoading ? <Loading /> :
              <div className={theme}>
                <header>
                  <Header props={props}/>
                </header>
                <div className='nehContentWrapper disableTextSelection'>
                  <h1 className='nehContentTitle'>{activePath || company_details.title}</h1>
                  <div className='nehContent'>
                    <Component {...props} />
                  </div>
                </div>
                <footer>FOOTER</footer>
              </div>
          }
          </div>
        )
      }}
    />
  )
}

export { NormalRoute }
