import { FC, createContext, useState } from 'react'

type LoadingContext = { isLoading: boolean, setIsLoading: (loading: boolean) => void };

export const LoadingContext = createContext<LoadingContext>(
  {} as LoadingContext
)

const LoadingProvider: FC = ({ children }) => {
  const [isLoading, updateIsLoading] = useState<boolean>(false)
  const setIsLoading = (loading: boolean) => updateIsLoading(loading)
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
