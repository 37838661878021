import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { NormalRoute } from './routes'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ComponentRouter = () => {

  const NotFound = () => {
    return (
      <>
        <h1>Error 404!</h1>
        <h5>Page Not Found.</h5>
      </>
    )
  }

  return (
    <Router>
      <Switch>
        <NormalRoute component={NotFound}/>
      </Switch>
    </Router>
  )
}

export default ComponentRouter
