import { FC } from 'react'
import './Loading.module.scss'

export const Loading:FC = () => {
  return(
    <div className='loader-wrapper'>
      <div className='loader'/>
    </div>
  )
}
