import { FC, useContext } from 'react'
import Switch from 'react-switch'
import { ThemeContext } from '../../../features/hooks/useThemeContext'
import { ThemeType } from '../../../utils/enums'

import styles from './toggleTheme.module.scss'

export const ToggleTheme:FC =  () => {

  const { theme, toggleTheme } = useContext(ThemeContext)

  return (
    <div className={styles.theme_toggle_wrapper}>
      <span className={styles.theme_toggle_title}>THEME</span>
      <Switch checkedIcon={<span className={styles.theme_toggle_icon}>🌞</span>} uncheckedIcon={<span className={styles.theme_toggle_icon}>🌜</span>} onChange={toggleTheme} checked={theme === ThemeType.LIGHT} />
    </div>
  )
}
