import { BrowserRouter } from 'react-router-dom'
import LoadingProvider from './features/hooks/useLoadingContext'
import { ThemeProvider } from './features/hooks/useThemeContext'
import ComponentRouter from './router'

import './App.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <LoadingProvider>
          <ComponentRouter />
        </LoadingProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
