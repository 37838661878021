import { FC, useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { company_details, navigations } from '../../../utils/constants'
import { tripStyles } from '../../../utils/mockData'
import { ToggleTheme } from '../index'

interface HeaderProps {
  props: RouteComponentProps<{[p: string]: string | undefined}>
}

export const Header:FC<HeaderProps> = ({ props }) => {
  const { pathname } = props.location
  const [activeUrl, setActiveUrl] = useState('')

  useEffect(()=>{
    setActiveUrl(pathname)
  },[pathname])

  return(
    <div className={styles.header_wrapper}>
      <div className={styles.nav_logo_wrapper}>
        <Link to='/'>
          <img src={company_details.logo} alt='company logo' className={styles.nav_company_logo} />
          <h3 className={styles.nav_company_title}>{company_details.title}</h3>
        </Link>
      </div>
      <ul className={styles.nav_links_wrapper}>
        <li className={`${styles.nav_link} ${activeUrl === navigations.home.url ? styles.active_nav_link : ''}`}>
          <Link to={navigations.home.url}>
            {navigations.home.displayName}
          </Link>
          <span className={styles.nav_bottom_border} />
        </li>
        {tripStyles.map((ts, i) => (
          <li key={i} className={`${styles.nav_link} ${activeUrl === ts.url ? styles.active_nav_link : ''}`}>
            <Link to={ts.url}>
              {ts.displayName}
            </Link>
            <span className={styles.nav_bottom_border} />
          </li>
        ))}
        <li className={`${styles.nav_link} ${activeUrl === navigations.companyProfile.url ? styles.active_nav_link : ''}`}>
          <Link to={navigations.companyProfile.url}>
            {navigations.companyProfile.displayName}
          </Link>
          <span className={styles.nav_bottom_border} />
          <ul className={styles.sub_nav_links_wrapper}>
            {navigations.companyProfile.sub_navs.map((cp, i) => (
              <li key={i} className={styles.sub_nav_link}>
                <Link to={cp.url}>
                  {cp.displayName}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
      <div className={styles.nav_action_buttons}>
        <ToggleTheme />
      </div>
    </div>
  )
}

import styles from './header.module.scss'
