const tripStyles = [
  {
    displayName: 'Trek',
    url: '/trek',
  },
  {
    displayName: 'Tour',
    url: '/tour',
  },
  {
    displayName: 'Adventure Activities',
    url: '/adventure-activities',
  },
  {
    displayName: 'Heli Tour',
    url: '/heli-tour',
  },
  {
    displayName: 'Hiking',
    url: '/hiking',
  },
]

export { tripStyles }
