const company_details = {
  title: 'NEPAL EVEREST\nHOLIDAYS PVT. LTD.' ,
  name: 'NEPAL EVEREST HOLIDAYS',
  logo: '/images/COMPANY_LOGO.png',
}

const navigations = {
  home: {
    url: '/',
    displayName: 'Home',
  },
  companyProfile:{
    url: '/company-profile',
    displayName: 'Company Profile',
    sub_navs:[
      { displayName: 'About us', url: '/about-us' },
      { displayName: 'About us', url: '/about-us' },
      { displayName: 'About us', url: '/about-us' },
      { displayName: 'About us', url: '/about-us' },
      { displayName: 'About us', url: '/about-us' },
    ]
  }
}


export { navigations, company_details }
